import React from "react";
import { Row } from "reactstrap";
import { UseTranslation } from "i18n/Translation";
import SectionBadge from "components/Sections/SectionBadge.jsx";
import PropTypes from "prop-types";

import "./SectionBadges.css";

class SectionBadges extends React.Component {
  render() {
    const { t } = this.props.translation;

    return (
        <section className="section-badges" id="badges">
        <div className="text-center">
          <Row>
            <SectionBadge
              img={require("assets/img/badge-flash.png")}
              title={t("home.badgessection.badge1.title")}
            />
            <SectionBadge
              img={require("assets/img/badge-shield.png")}
              title={t("home.badgessection.badge2.title")}
            />
            <SectionBadge
              img={require("assets/img/badge-bank.png")}
              title={t("home.badgessection.badge3.title")}
            />
          </Row>
        </div>
      </section>
    );
  }
}

SectionBadges.propTypes = {
  translation: PropTypes.object.isRequired
};

export default UseTranslation(SectionBadges);
