import React from "react";
import PropTypes from "prop-types";

const Context = React.createContext({});

const ContexConsumer = Context.Consumer;

const ContexProvider = Context.Provider;

export function UseLoginContext(Component) {
  return function WrapperComponent(props) {
    return (
      <ContexConsumer>
        {state => <Component {...props} loginContext={state} />}
      </ContexConsumer>
    );
  };
}

export default class ProvideLoginContex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false,
      login: this.login
    };
  }

  login = () => this.setState({ loggedIn: true });

  render() {
    return (
      <div>
        <ContexProvider value={this.state}>
          {this.props.children}
        </ContexProvider>
      </div>
    );
  }
}

ProvideLoginContex.propTypes = {
  children: PropTypes.object
};
