import React from "react";
import PropTypes from "prop-types";

const RdvContex = React.createContext({});

const RdvContexConsumer = RdvContex.Consumer;

const RdvContexProvider = RdvContex.Provider;

export function UseRdvContext(Component) {
  return function WrapperComponent(props) {
    return (
      <RdvContexConsumer>
        {state => <Component {...props} rdvContext={state} />}
      </RdvContexConsumer>
    );
  };
}

export default class ProvideRdvContex extends React.Component {
  constructor(props) {
    super(props);

    var bCalendars = JSON.parse(localStorage.getItem("BOOKING-CALENDAR"));

    this.state = {
      bookingCalendars: bCalendars ? bCalendars : [],
      updateBookingCalendars: this.updateBookingCalendars.bind(this)
    };
  }

  updateBookingCalendars(bookingCalendars) {
    this.setState({ bookingCalendars });

    localStorage.setItem("BOOKING-CALENDAR", JSON.stringify(bookingCalendars));
  }

  render() {
    return (
      <div>
        <RdvContexProvider value={this.state}>
          {this.props.children}
        </RdvContexProvider>
      </div>
    );
  }
}

ProvideRdvContex.propTypes = {
  children: PropTypes.object
};
