import Home from "layouts/Home/Home.jsx";
import Result from "layouts/Home/Result.jsx";
import ExtraForm from "layouts/Home/ExtraForm.jsx";

var indexRoutes = [
  { path: "/result/rdv", name: "Result", component: Result },
  { path: "/result", name: "Result", component: Result },
  { path: "/extra-form", name: "ExtraForm", component: ExtraForm },
  { path: "/", name: "Home", component: Home }
];

export default indexRoutes;
