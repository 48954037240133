import React from "react";

class ReactLoader extends React.Component {
  render() {
    const { size } = this.props;

    return (
      <div className="text-center">
        {" "}
        <i className={`fas fa-spinner fa-spin fa-${size}x`} />{" "}
      </div>
    );
  }
}

export default ReactLoader;
