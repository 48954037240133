import React from "react";
import { Col } from "reactstrap";
import PropTypes from "prop-types";

import "./SectionCard.css";

class SectionCard extends React.Component {
  render() {
    return (
      <Col lg={4} md={6} className="portfolio-item">
        <div className="portfolio-link">
          <img className="img-fluid" src={this.props.img} alt="" />
          <div className="portfolio-caption">
            <h4 className="section-card-title">{this.props.title}</h4>
            <p className="text-muted">{this.props.text}</p>
          </div>
        </div>
      </Col>
    );
  }
}

SectionCard.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired
};

export default SectionCard;
