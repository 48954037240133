/* eslint-disable react/no-string-refs */
import React from "react";
import $ from "jquery";
import { Redirect } from "react-router-dom";
import ReactLoader from "components/Loader/ReactLoader.jsx";
import Footer from "components/Footer/Footer.jsx";
import { UseTranslation } from "i18n/Translation";
import { UseRdvContext } from "contexts/RdvContext.jsx";
import PropTypes from "prop-types";
import NotificationAlert from "react-notification-alert";
import { Container, Row, Col, Card, CardBody, CardFooter, Form, Progress } from "reactstrap";
import Menu from "components/Main/Menu";
import FirstExtraForm from "components/ExtraForm/FirstExtraForm";
import SecondExtraForm from "components/ExtraForm/SecondExtraForm";
import ThirdExtraForm from "components/ExtraForm/ThirdExtraForm";
//import ForthExtraForm from "components/ExtraForm/ForthExtraForm";
import FinalExtraForm from "components/ExtraForm/FinalExtraForm";

class ExtraForm extends React.Component {
  constructor(props) {
    super(props);

    const oppId = localStorage.getItem("OPPID");
    // If there is no opportunity opened, redirect to Home
    const shouldRedirectToHome = !oppId;

    this.state = {
      loading: false,
      showResult: false,

      oppId,
      shouldRedirectToHome,

      index: 0,
      //forms: [FirstExtraForm, SecondExtraForm, ThirdExtraForm, ForthExtraForm]
      forms: [FirstExtraForm, SecondExtraForm, ThirdExtraForm]
    };

    this.displayedForm = React.createRef();

    this.onSubmit = this.onSubmit.bind(this);
  }

  notify(type, msg) {
    const options = {
      place: "tr", // Top Right
      message: (
        <div>
          <div>{msg}</div>
        </div>
      ),
      type,
      icon: "fas fa-exclamation-triangle",
      autoDismiss: 5
    };

    // eslint-disable-next-line react/no-string-refs
    this.refs.notificationAlert.notificationAlert(options);
  }

  async onSubmit(e) {
    e.preventDefault();

    // Make the validation by the component itself
    if (this.displayedForm.current.validate()) {
      this.setState({ loading: true });

      // save the form (done by the component displayed)
      const result = await this.displayedForm.current.updateOpp(this.state.oppId);

      const nextIndex = this.state.index + 1;

      // Check if we displayed already all the forms already
      if (nextIndex < this.state.forms.length) {
        // Go to next page
        this.setState({
          loading: false,
          index: nextIndex
        });
      } else {
        // Show result
        this.setState({
          loading: false,
          showResult: true
        });
        this.props.rdvContext.updateBookingCalendars(result.bookingCalendars);
      }

      $("html, body").animate({ scrollTop: $($("#extra-form")).offset().top - 75 }, 500);
    }
    else {
      this.notify(
        "warning",
        this.props.translation.t("home.form.not.complete.popup")
      );
    }
  }

  getForm = (index) => this.state.forms[index || this.state.index];

  render() {
    const { translation } = this.props;
    const { showResult, shouldRedirectToHome } = this.state;
    const Component = this.getForm();

    if (shouldRedirectToHome) {
      return <Redirect to={"/"} />;
    }

    // Calculate a form is how much percent
    const unit = Math.round((100 / this.state.forms.length));
    // From the index fill the progress bar
    let progression = Math.round(this.state.index * (100 / this.state.forms.length));
    // We display the progress of each step at start
    progression += unit;

    return (
      <div className="wrapper">
        <NotificationAlert ref="notificationAlert" />
        <Menu hideAlert={true} />
        <div className="main-panel" ref="mainPanel">
          <div className="content mt-125">
            <Container id="extra-form">
              <Row>
                <Col>
                  <Form onSubmit={this.onSubmit}>
                    <Card className="padding-25">
                      <Progress hidden={showResult} className="mb-3" value={showResult ? 100 : (this.state.index * (100 / this.state.forms.length) + (100 / this.state.forms.length))} >
                        {progression} %
                      </Progress>
                      <CardBody>
                          {
                            showResult
                            ? <FinalExtraForm />
                            : <Component ref={this.displayedForm}/>
                          }
                      </CardBody>
                      <CardFooter className="text-center" hidden={showResult} >
                        <button
                          className="btn btn-loader"
                          disabled
                          hidden={!this.state.loading}
                        >
                          <ReactLoader size={2} />
                        </button>
                        <input
                          type="submit"
                          className="btn btn-primary btn-lg rounded-pill"
                          value={translation.t("home.form.btn.continue")}
                          hidden={this.state.loading}
                        />
                        <Progress value={showResult ? 100 : (this.state.index * (100 / this.state.forms.length) + (100 / this.state.forms.length))} >
                          {progression} %
                        </Progress>
                      </CardFooter>
                    </Card>
                  </Form>
                </Col>
              </Row>
            </Container>
          </div>
          <Footer fluid />
        </div>
        <a href="/result" data-scroll className="floating-btn" >
          <i class="fas fa-angle-double-left my-float"></i>
        </a>
      </div>
    );
  }
}

ExtraForm.propTypes = {
  translation: PropTypes.object.isRequired
};

export default UseRdvContext(UseTranslation(ExtraForm));
