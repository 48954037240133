import { getHorsepowerComboOptions, getGearBoxComboOptions } from "./AWS/aws";

// If set to true we display the third form (with the sellerFinalAcceptablePrice & sellerPatienceLevel)
const shouldDisplayThirdForm = true;
// If set to true we display the arguments for purchasing step before redirect to price
const shouldDisplayArgumentForm = true;

/**
 * Options for horse power combo
 */
interface HPowerComboOptions {
  value: Number;
  label: String;
};

/**
 * Returns a list of combo options for horse power combo
 * @param carModelComboId Car model combo id
 * @param carFirstRegistration Car first registration
 * @param carFuelEnum Car fuel enum
 */
const getHPowerComboOptions = async (carModelComboId: String, carFirstRegistration: Number, carFuelEnum: String) : Promise<Array<HPowerComboOptions>> => {
  const data = (await getHorsepowerComboOptions(
    carModelComboId,
    carFirstRegistration,
    carFuelEnum
  )) as Array<HPowerComboOptions>;

  return data.map(mdata => (<HPowerComboOptions>{
    value: mdata.value,
    label: mdata.label
  }));
};

/**
 * Option for gear box combo
 */
interface GearBoxComboOptions {
  value: String;
  label: String;
};

/**
 * Returns a list of combo options for the horse power combo
 * @param carModelComboId Car model combo id
 * @param carFirstRegistration Car first registration
 * @param carFuelEnum Car fuel enum
 */

 /**
 * Returns a list of combo options for the gear box combo
 * @param carModelComboId Car model combo id
 * @param carFirstRegistration Car first registration
 * @param carFuelEnum Car fuel enum
  * @param powerKw Power
  */
const  getGearBoxes = async (carModelComboId: String, carFirstRegistration: Number, carFuelEnum: String, powerKw: Number) : Promise<Array<GearBoxComboOptions>> => {
  const data = (await getGearBoxComboOptions(
    carModelComboId,
    carFirstRegistration,
    carFuelEnum,
    powerKw
  )) as Array<GearBoxComboOptions>;

  return data.map(mdata => (<GearBoxComboOptions>{
    value: mdata.value,
    label: mdata.label
  }));
};

export {
  shouldDisplayThirdForm,
  shouldDisplayArgumentForm,

  getHPowerComboOptions,
  getGearBoxes };
