import React from 'react';
import { updateOpp } from "AWS/aws";
import { TranslationConsumer } from "i18n/Translation";
import SwitchInput from './SwitchInput';
import SelectInput from './SelectInput';

class FirstExtraForm extends React.Component {
  state = {
    fullMaintenanceHistory: false,
    damaged: false,
    interiorState: undefined,
    exteriorState: undefined,
    smoker: false,
    transformations: false,
    dashboardAlert: false,
    engineOK: false,
    gearboxOK: false,
    steeringOK: false,
    suspensionOK: false,
    brakesOK: false,
    aircoOk: false,
  }

  validate() {
    for (var property in this.state) {
      if (this.state[property] || this.state[property] === false) {
        continue;
      }
      return false;
    }
    return true;
  }

  updateOpp = (oppId) => updateOpp(oppId, this.state);

  render() {
    return (
        <TranslationConsumer>
          {translation =>
            <div>
            <SelectInput
              name={"interiorState"}
              tooltiptext={translation.t("extra.form.interiorState.tooltip")}
              label={translation.t("extra.form.interiorState.label")}
              onChange={val => this.setState({ interiorState: val })}
              value={this.state.interiorState}
            />
            <SelectInput
              name={"exteriorState"}
              tooltiptext={translation.t("extra.form.exteriorState.tooltip")}
              label={translation.t("extra.form.exteriorState.label")}
              onChange={val => this.setState({ exteriorState: val })}
              value={this.state.exteriorState}
            />
              <SwitchInput
                name="fullMaintenanceHistory"
                tooltiptext={translation.t("extra.form.fullMaintenanceHistory.tooltip")}
                label={translation.t("extra.form.fullMaintenanceHistory.label")}
                onChange={val => this.setState({ fullMaintenanceHistory: val })}
                value={this.state.fullMaintenanceHistory}
              />

              <SwitchInput
                name="engineOK"
                tooltiptext={translation.t("extra.form.engineOK.tooltip")}
                label={translation.t("extra.form.engineOK.label")}
                onChange={val => this.setState({ engineOK: val })}
                value={this.state.engineOK}
              />
              <SwitchInput
                name="steeringOK"
                tooltiptext={translation.t("extra.form.steeringOK.tooltip")}
                label={translation.t("extra.form.steeringOK.label")}
                onChange={val => this.setState({ steeringOK: val })}
                value={this.state.steeringOK}
              />
              <SwitchInput
                name="suspensionOK"
                tooltiptext={translation.t("extra.form.suspensionOK.tooltip")}
                label={translation.t("extra.form.suspensionOK.label")}
                onChange={val => this.setState({ suspensionOK: val })}
                value={this.state.suspensionOK}
              />
              <SwitchInput
                name="brakesOK"
                tooltiptext={translation.t("extra.form.brakesOK.tooltip")}
                label={translation.t("extra.form.brakesOK.label")}
                onChange={val => this.setState({ brakesOK: val })}
                value={this.state.brakesOK}
              />
              <SwitchInput
                name="aircoOk"
                tooltiptext={translation.t("extra.form.aircoOk.tooltip")}
                label={translation.t("extra.form.aircoOk.label")}
                onChange={val => this.setState({ aircoOk: val })}
                value={this.state.aircoOk}
              />

              <SwitchInput
                name="damaged"
                tooltiptext={translation.t("extra.form.damaged.tooltip")}
                label={translation.t("extra.form.damaged.label")}
                onChange={val => this.setState({ damaged: val })}
                value={this.state.damaged}
              />

              <SwitchInput
                name="smoker"
                tooltiptext={translation.t("extra.form.smoker.tooltip")}
                label={translation.t("extra.form.smoker.label")}
                onChange={val => this.setState({ smoker: val })}
                value={this.state.smoker}
              />
              <SwitchInput
                name="transformations"
                tooltiptext={translation.t("extra.form.transformations.tooltip")}
                label={translation.t("extra.form.transformations.label")}
                onChange={val => this.setState({ transformations: val })}
                value={this.state.transformations}
              />
              <SwitchInput
                name="dashboardAlert"
                tooltiptext={translation.t("extra.form.dashboardAlert.tooltip")}
                label={translation.t("extra.form.dashboardAlert.label")}
                onChange={val => this.setState({ dashboardAlert: val })}
                value={this.state.dashboardAlert}
              />
            </div>
          }
        </TranslationConsumer>
    );
  }
}

export default FirstExtraForm;
