/* eslint-disable react/no-string-refs */
import React from "react";
import $ from "jquery";
import { UseTranslation } from "i18n/Translation";
import { Redirect } from "react-router-dom";
import Main from "components/Main/Main.jsx";
import Footer from "components/Footer/Footer.jsx";
import SectionInformation from "components/Sections/SectionInformation.jsx";
import SectionContact from "components/Sections/SectionContact.jsx";
import SectionVideo from "components/Sections/SectionVideo.jsx";
import SectionBadges from "components/Sections/SectionBadges.jsx";
import SectionPressBadges from "components/Sections/SectionPressBadges.jsx";
import CookieConsent from "react-cookie-consent";
import PropTypes from "prop-types";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      redirectToResult: false
    };

    this.estimateOnClick = this.estimateOnClick.bind(this);
  }

  estimateOnClick() {
    const oppId = localStorage.getItem("OPPID");
    $("html, body").animate({ scrollTop: 0 }, 300);
    if (oppId) {
      this.setState({ redirectToResult: true });
    }
  }

  render() {
    const { translation } = this.props;

    if (this.state.redirectToResult) {
      return <Redirect to={"result"} />;
    }

    return (
      <div className="wrapper">
        <CookieConsent
          style={{ background: "#212529" }}
          buttonClasses="btn btn-primary btn-lg rounded-pill"
          hideOnAccept={true}
          acceptOnScrollPercentage={25}
          buttonStyle={{
            background: "#0062cc",
            border: 0,
            borderRadius: "7px",
            boxShadow: "none",
            color: "white",
            cursor: "pointer",
            padding: "10px 15px",
            margin: "15px"
          }}
          buttonText={translation.t("cookie.consent.button.accept")}
        >
          <div className="container">
            <p>{translation.t("cookie.consent.text")}</p>
          </div>
        </CookieConsent>
        <div className="main-panel" ref="mainPanel">
          <div className="content">
            <Main {...this.props} routes={[]} />
            <div className="container container-badges">
              <SectionBadges />
            </div>

            <div className="container container-press-badges">
              <SectionPressBadges />
            </div>

            <div className="container">
              <SectionVideo />
            </div>

            <div className="container">
              <SectionInformation />
              <section className="text-center estimate-button">
                <button
                  className="btn btn-primary"
                  onClick={this.estimateOnClick}
                >
                  {translation.t("home.button.estimate.my.vehicule")}
                </button>
              </section>
            </div>
            <SectionContact />
          </div>
          <Footer fluid />
        </div>
      </div>
    );
  }
}

Home.propTypes = {
  translation: PropTypes.object.isRequired
};

export default UseTranslation(Home);
