import Amplify from "aws-amplify";
import config from "config.js";

/*
const config = {
  MAX_ATTACHMENT_SIZE: 5000000,
  apiGateway: {
    REGION: process.env.REACT_APP_apiGateway_REGION,
    URL: process.env.REACT_APP_apiGateway_URL
  },
  cognito: {
    REGION: process.env.REACT_APP_cognito_REGION,
    USER_POOL_ID: process.env.REACT_APP_cognito_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_cognito_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_cognito_IDENTITY_POOL_ID
  }
};
*/

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: "auditorApi",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      }
    ]
  },
  Analytics: {
    disabled: true
  }
});

export const authEmail = config.REACT_APP_email_AUTH;
export const authPassword = config.REACT_APP_email_PASS;
export const emailToken = config.REACT_APP_email_TOKEN;
