/* eslint-disable no-console */
import React from "react";
import { UsePriceContext } from "contexts/PriceContext.jsx";
import { getOpp } from "AWS/aws";
import { Redirect } from "react-router-dom";
import { UseTranslation } from "i18n/Translation";
import {
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  Container,
  NavbarToggler,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert
} from "reactstrap";
import PropTypes from "prop-types";

class Menu extends React.Component {
  constructor(params) {
    super(params);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      redirectToPrice: false,
      displayAlert: false
    };
  }

  UNSAFE_componentWillMount() {
    const oppId = localStorage.getItem("OPPID");
    if (oppId && this.props.hideAlert === false) {
      this.props.priceContext.updatePrice(localStorage.getItem("PRICE"));
      getOpp(oppId)
        .then(ret => {
          const opp = ret.list[0];
          this.props.priceContext.updateSellerInfo(oppId, {
            vendorFirstName: opp.vendorFirstName,
            vendorName: opp.vendorName,
            vendorEmail: opp.vendorEmail,
            seller_phone: opp.seller_phone,
            carBrand: opp.carBrand,
            carModel: opp.carModel
          });

          this.setState({ displayAlert: true });
        })
        .catch(err => {
          console.log("ERROR ON getOPP", err);
        });
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    const { t, changeCurrentLanguage } = this.props.translation;
    let translate = this.props.translation.t(
      "home.title.haveprice",
      this.props.priceContext.sellerInfo
    );

    const hasAllInfo =
      this.props.priceContext.sellerInfo.carBrand &&
      this.props.priceContext.sellerInfo.carModel;

    const { redirectToPrice } = this.state;
    if (redirectToPrice) {
      return <Redirect to={"result/rdv"} />;
    }

    return (
      <div>
        <Navbar id="mainNav" expand="lg" fixed="top" light={true}>
          <Container>
            <NavbarBrand href="/">
              <img
                className="header-logo"
                alt="logo"
                src={require("assets/img/taktakbe.png")}
              />
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle}>
              <i className="fas fa-bars" />
            </NavbarToggler>
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink href="/#services" data-scroll>
                    {t("home.header.services")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/#contact">{t("home.header.contact")}</NavLink>
                </NavItem>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    <i className="nc-icon nc-world-2" />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={() => changeCurrentLanguage("en")}>
                      EN
                    </DropdownItem>
                    <DropdownItem onClick={() => changeCurrentLanguage("fr")}>
                      FR
                    </DropdownItem>
                    <DropdownItem onClick={() => changeCurrentLanguage("nl")}>
                      NL
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
        {this.props.hideAlert === false &&
        this.state.displayAlert &&
        hasAllInfo ? (
          <Alert
            id="alertPrice"
            color="success"
            className="text-center"
            onClick={() => this.setState({ redirectToPrice: true })}
          >
            {translate}
            <br />
            <h5 className="mb-0">{this.props.priceContext.price} EUR !</h5>
            <button className="btn btn-primary">
              {t("home.title.haveprice.underprice")}
            </button>
          </Alert>
        ) : null}
      </div>
    );
  }
}

Menu.propTypes = {
  translation: PropTypes.object.isRequired,
  priceContext: PropTypes.object.isRequired,
  hideAlert: PropTypes.bool
};

export default UseTranslation(UsePriceContext(Menu));
