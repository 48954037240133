import React, { Component } from "react";
import { FormGroup } from "reactstrap";
import PropTypes from "prop-types";

const Compress = require("client-compress");

class InputFile extends Component {
  placeholder = require("assets/img/img-placeholder.png");
  constructor(props) {
    super(props);

    this.state = {
      file: this.placeholder,
      base64: ""
    };

    this.handleChange = this.handleChange.bind(this);
  }

  // Returns the image to upload base64 encoded
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  async handleChange(event) {
    event.persist();
    if (event.target.files.length > 0) {
      const options = {
        targetSize: 0.5,
        quality: 0.75,
        qualityStepSize: 0.05,
        minQuality: 0.25,
        maxHeight: 1080
      };
      const compress = new Compress(options);
      var file = await compress.compress([event.target.files[0]]);
      file = file[0].photo.data;
      var base64 = await this.getBase64(file);

      const extension = event.target.files[0].name.split('.').slice(-1);

      var imageInput = {
        type: file.type,
        imageName: this.props.name + '.' + extension,
        file: base64.split(",")[1]
      };

      this.props.onChange(this.props.name, imageInput);

      this.setState({
        file: URL.createObjectURL(event.target.files[0]),
        base64
      });
    }
  }

  render() {
    const { name } = this.props;

    return (
      <FormGroup>
        <div className="text-center">
          <input
            name={name}
            type="file"
            onChange={this.handleChange}
            accept="image/*"
          />
          <img
            className="img-placeholder"
            src={this.state.file}
            alt="..."
          />
        </div>
      </FormGroup>
    );
  }
}

InputFile.propTypes = {
  name: PropTypes.string.isRequired
};

export default InputFile;
