import React from 'react';
import { FormGroup } from "reactstrap";
import Switch from "react-switch";
import MyToolTip from 'components/Tooltip/MyToolTip';

const SwitchInput = (props) => (
  <FormGroup>
    <h6 className="pd6">
      <MyToolTip name={props.name} tooltiptext={props.tooltiptext} />
      {props.label}
      {" *"}
      <br />
      <Switch className="react-switch mt-3" onChange={props.onChange} checked={props.value} />
    </h6>
  </FormGroup>
);

export default SwitchInput;