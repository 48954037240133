import React from 'react';
import { CardBody, Row, Col } from 'reactstrap';
import { TranslationConsumer } from "i18n/Translation";
import InputFile from './InputFile';
import { saveImage, updateOpp } from 'AWS/aws';

class ThirdExtraForm extends React.Component {
  state = {
    damageImages: []
  }

  cptr = 0;

  onChange = (key, data) => {
    let { damageImages } = this.state;

    for (let i = 0; i < damageImages.length; i++) {
      const image = damageImages[i];
      if (image.id === key) {
        image.data = data;
        break;
      }
    }

    this.setState({ damageImages })
  };

  onAddImage() {
    let { damageImages } = this.state;

    damageImages.unshift({
      id: 'photos_damages_' + this.cptr++,
      data: null
    });

    this.setState({damageImages});
  }

  removeImage(key) {
    const array = this.state.damageImages.filter(function(damageImage){
      return damageImage.id !== key;
    });
    this.setState({ damageImages: array });
  }

  validate() {
    for (let i = 0; i < this.state.damageImages.length; i++) {
      const damageImage = this.state.damageImages[i];
      if (damageImage.data) {
        continue;
      }
      return false;
    }
    return true;
  }

  updateOpp(oppId) {
    return Promise.all(
      this.state.damageImages.map(imageInfo => saveImage(oppId, imageInfo.data))
    );
  }

  updateOpp = (oppId) => updateOpp(oppId, this.state);

  render() {
    return (
      <TranslationConsumer>
        {
          translation =>
            <div>
              <h5>{translation.t("extra.form.photos_damages.text")}</h5>
              <Row className="text-center">
                <button
                  type="button"
                  onClick={() => this.onAddImage()}
                  className="btn btn-warning"
                >
                  +
                </button>
              </Row>
              <Row className="align-items-center mb-5">
              {
                this.state.damageImages.map(damageImage =>
                  <React.Fragment key={damageImage.id}>
                    <Col sm={12} md={4}>
                      <button
                        type="button"
                        onClick={() => this.removeImage(damageImage.id)}
                        className="btn btn-danger btn-remove-img"
                      >
                        -
                      </button>
                      <CardBody className="text-left align-items-center d-flex">
                        <InputFile onChange={this.onChange} name={damageImage.id} />
                      </CardBody>
                    </Col>
                  </React.Fragment>
                )
              }
              </Row>
            </div>
        }
      </TranslationConsumer>
    );
  }
}

export default ThirdExtraForm;
