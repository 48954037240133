/* eslint-disable react/prop-types */
import React from "react";
import fr from "./fr.json";
import en from "./en.json";
import nl from "./nl.json";

const Translation = React.createContext({});

export const TranslationConsumer = Translation.Consumer;

const TranslationProvider = Translation.Provider;

export function UseTranslation(Component) {
  return function WrapperComponent(props) {
    return (
      <TranslationConsumer>
        {state => <Component {...props} translation={state} />}
      </TranslationConsumer>
    );
  };
}

export default class ProvideTranslation extends React.Component {
  translations = {
    en,
    fr,
    nl
  };
  defaultLg = "en";
  currentLg = "en";
  carModelTranslation = undefined;
  carFuelTranslation = undefined;
  gearBoxTranslation = undefined;

  constructor(props) {
    super(props);

    if (props.defaultLg) {
      this.defaultLg = props.defaultLg;
    }

    if (props.currentLg) {
      this.currentLg = props.currentLg;
    }

    this.state = {
      update: true,
      t: this.t.bind(this),
      format: this.format,
      formatcarModelTranslation: this.formatcarModelTranslation.bind(this),
      formatcarFuelTranslation: this.formatcarFuelTranslation.bind(this),
      formatGearBoxTranslation: this.formatGearBoxTranslation.bind(this),
      formatGeneralFormTranslation: this.formatGeneralFormTranslation.bind(
        this
      ),
      changeCurrentLanguage: this.changeCurrentLanguage.bind(this),
      getVideos: this.getVideos.bind(this)
    };

    this.carModelTranslation = {
      door_plural: this.t("door_plural"),
      bodyId_1: this.t("enum.bodyType.citadine"),
      bodyId_2: this.t("enum.bodyType.cabriolet"),
      bodyId_3: this.t("enum.bodyType.coupe"),
      bodyId_4: this.t("enum.bodyType.suv"),
      bodyId_5: this.t("enum.bodyType.break"),
      bodyId_6: this.t("enum.bodyType.berline"),
      bodyId_7: this.t("enum.bodyType.autre"),
      bodyId_12: this.t("enum.bodyType.monospace"),
      bodyId_13: this.t("enum.bodyType.utilitaire")
    };

    this.carFuelTranslation = {
      carFuelId_B: this.t("enum.fuel.benzin"),
      carFuelId_D: this.t("enum.fuel.diesel"),
      carFuelId_E: this.t("enum.fuel.electric"),

      carFuelId_M: this.t("enum.fuel.ethanol"),
      carFuelId_H: this.t("enum.fuel.hydrogen"),
      carFuelId_L: this.t("enum.fuel.lpg"),
      carFuelId_C: this.t("enum.fuel.cng"),
      carFuelId_2: this.t("enum.fuel.benzin-elec"),
      carFuelId_0: this.t("enum.fuel.other"),
      carFuelId_3: this.t("enum.fuel.diesel-elec"),

      carFuelId_G: this.t("enum.fuel.lpg"),
      "carFuelId_D-E": this.t("enum.fuel.diesel-elec"),
      "carFuelId_B-E": this.t("enum.fuel.gas-elec"),
      carFuelId_O: this.t("enum.fuel.other")
    };

    this.gearBoxTranslation = {
      carGearboxId_A: this.t("enum.gearbox.automatic"),
      carGearboxId_M: this.t("enum.gearbox.manual"),
      carGearboxId_SA: this.t("enum.gearbox.semiautomatic")
    };

    this.generalFormTranslation = {
      general_0: this.t("general_0"),
      general_1: this.t("general_1"),
      general_2: this.t("general_2"),
      general_3: this.t("general_3"),
      general_4: this.t("general_4"),
      general_5: this.t("general_5")
    };
  }

  format(translation, formatObj) {
    if (formatObj) {
      for (var property in formatObj) {
        translation = translation.replace(
          "{" + property + "}",
          formatObj[property]
        );
      }
    }
    return translation;
  }

  formatcarModelTranslation(translation) {
    return this.format(translation, this.carModelTranslation);
  }

  formatcarFuelTranslation(translation) {
    return this.format(translation, this.carFuelTranslation);
  }

  formatGearBoxTranslation(translation) {
    return this.format(translation, this.gearBoxTranslation);
  }

  formatGeneralFormTranslation(translation) {
    return this.format(translation, this.generalFormTranslation);
  }

  t(translationCode, dataToReplace) {
    var languageTranslations = this.translations[this.currentLg];
    let trans = "";
    if (languageTranslations) {
      trans = languageTranslations[translationCode];
    }

    if (trans) {
      return this.format(trans, dataToReplace);
    }

    trans = this.translations[this.defaultLg][translationCode];

    if (trans) {
      return this.format(trans, dataToReplace);
    }

    return translationCode;
  }

  getVideos() {
    var languageTranslations = this.translations[this.currentLg];
    let videos = [];

    for (var key in languageTranslations) {
      if (key.startsWith("video.url.")) videos.push(languageTranslations[key]);
    }

    return videos;
  }

  changeCurrentLanguage(lgCode) {
    this.currentLg = lgCode;
    // Force the update of all the transaltions
    this.setState({ update: !this.update });
  }

  render() {
    return (
      <div>
        <TranslationProvider value={this.state}>
          {this.props.children}
        </TranslationProvider>
      </div>
    );
  }
}
