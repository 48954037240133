import React from "react";
import { FormGroup } from "reactstrap";
import { UseTranslation } from "i18n/Translation";
import PropTypes from "prop-types";
import {
  mobileAwareSelect,
  mileageComboOptions
} from "variables/constants.jsx";

class FormSecond extends React.Component {
  constructor(params) {
    super(params);

    this.state = {
      disableCarHorsepowerSelect: true,
      disableCarGearboxSelect: true,
      carHorsepowerOptions: [],
      carGearboxOptions: []
    };
  }

  componentDidMount() {
    this.setState({
      carHorsepowerOptions: this.props.hpCombo,
      disableCarHorsepowerSelect: false
    });
  }

  getGearBoxes(powerKw) {
    this.props
      .getGearBoxes(powerKw)
      .then(data => {
        this.setState({
          carGearboxOptions: data.map(mdata => ({
            value: mdata.value,
            label: this.props.translation.formatGearBoxTranslation(mdata.label)
          })),
          disableCarGearboxSelect: false
        });
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log("ERROR ON getGearBoxes ", err);
      });
  }

  render() {
    const { t } = this.props.translation;

    return (
      <div>
        <FormGroup>
          <h6 className="pd6">
            {t("home.form.horsePower")}
            {" *"}
          </h6>
          {mobileAwareSelect(
            "carHorsepower",
            val => {
              this.props.onChange({
                target: { name: "carHorsepower", value: val.value }
              });
              this.getGearBoxes(val.value);
            },
            this.state.carHorsepowerOptions,
            this.state.disableCarHorsepowerSelect
          )}
        </FormGroup>
        <FormGroup>
          <h6 className="pd6">
            {t("home.form.gearbox")}
            {" *"}
          </h6>
          {mobileAwareSelect(
            "carGearboxEnum",
            val => {
              this.props.onChange({
                target: { name: "carGearboxEnum", value: val.value }
              });
            },
            this.state.carGearboxOptions,
            this.state.disableCarGearboxSelect
          )}
        </FormGroup>
        <FormGroup>
          <h6 className="pd6">
            {t("home.form.mileage")}
            {" *"}
          </h6>
          {mobileAwareSelect(
            "carMileage",
            val => {
              this.props.onChange({
                target: { name: "carMileage", value: val.value }
              });
            },
            mileageComboOptions,
            false
          )}
        </FormGroup>
      </div>
    );
  }
}

FormSecond.propTypes = {
  translation: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  getGearBoxes: PropTypes.func.isRequired,
  hpCombo: PropTypes.array.isRequired
};

export default UseTranslation(FormSecond);
