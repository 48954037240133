import React from 'react';
import { UseTranslation } from "i18n/Translation";
import { FormGroup } from "reactstrap";
import { mobileAwareSelect, extraFormOptions } from "variables/constants.jsx";
import MyToolTip from 'components/Tooltip/MyToolTip';

const SelectInput = (props) => {
  const { t } = props.translation;
  return (
    <FormGroup>
      <h6 className="pd6">
        <MyToolTip name={props.name} tooltiptext={props.tooltiptext} />
        {props.label}
        {" *"}
        <br />
        <br />
        {mobileAwareSelect(
              props.name,
              val => {
                props.onChange(val.value);
              },
              extraFormOptions.map(elem => {
                return { ...elem, label: t(elem.label) };
              }),
              false
            )}
      </h6>
  
    </FormGroup>
  );
};

export default UseTranslation(SelectInput);