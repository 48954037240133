import React from "react";
import { Row } from "reactstrap";
import SectionCard from "components/Sections/SectionCard.jsx";
import { UseTranslation } from "i18n/Translation";
import PropTypes from "prop-types";

class SectionInformation extends React.Component {
  render() {
    const { t } = this.props.translation;

    return (
      <section className="section-informations" id="services">
        <div className="text-center">
          <h1 className="sgtitle">{t("home.servicesection.title")}</h1>
        </div>
        <Row>
          <SectionCard
            img={require("assets/img/A1.jpg")}
            title={t("home.servicesection.card1.title")}
            text={t("home.servicesection.card1.text")}
          />
          <SectionCard
            img={require("assets/img/B1.jpg")}
            title={t("home.servicesection.card2.title")}
            text={t("home.servicesection.card2.text")}
          />
          <SectionCard
            img={require("assets/img/C1.jpg")}
            title={t("home.servicesection.card3.title")}
            text={t("home.servicesection.card3.text")}
          />
        </Row>
      </section>
    );
  }
}

SectionInformation.propTypes = {
  translation: PropTypes.object.isRequired
};

export default UseTranslation(SectionInformation);
