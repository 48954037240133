const dev = {
	apiGateway: {
		REGION: "eu-central-1",
		URL: "https://amt1qlrmv3.execute-api.eu-central-1.amazonaws.com/dev"
	},
	cognito: {
		REGION: "eu-central-1",
		USER_POOL_ID: "eu-central-1_ptjPknILF",
		APP_CLIENT_ID: "6ggi9l6a7v4c4cumjp3usjvt",
		IDENTITY_POOL_ID: "eu-central-1:a9cdfec3-6e78-4b26-ade4-fa35f7a9d329"
	}
};

const prod = {
	apiGateway: {
		REGION: "eu-central-1",
		URL: "https://4tlf7pnqwe.execute-api.eu-central-1.amazonaws.com/prod"
	},
	cognito: {
		REGION: "eu-central-1",
		USER_POOL_ID: "eu-central-1_bAGR4IPrr",
		APP_CLIENT_ID: "5icu3d92l2d84r8ljt43ikcpnl",
		IDENTITY_POOL_ID: "eu-central-1:95adf0f6-1a9b-437a-8fe2-2a35101e87f0"
	}
};

const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  REACT_APP_email_AUTH: "public@taktak.co",
  REACT_APP_email_PASS: "Passw0rd!19$",
  REACT_APP_email_TOKEN: "IVaCd35Fyr",
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
