/* eslint-disable react/prop-types */
import React from "react";
import { Row, Col } from "reactstrap";
import PriceForm from "components/Form/PriceForm.jsx";
import { UseTranslation } from "i18n/Translation";
import { UsePriceContext } from "contexts/PriceContext.jsx";
import Menu from "./Menu";
import ResultHeader from "./ResultHeader.jsx";
import $ from "jquery";
import PropTypes from "prop-types";

class Main extends React.Component {
  constructor(params) {
    super(params);

    this.state = {
      hideAlert: false
    };
  }
  componentDidMount() {
    this.navbarCollapse();
    // Collapse the navbar when page is scrolled
    $(window).scroll(this.navbarCollapse);

    if (this.props.match.path === "/result/rdv") {
      setTimeout(() => {
        $("html, body").animate({ scrollTop: $($("#RDV")).offset().top }, 500);
      }, 1000);
    }
  }

  // Collapse Navbar
  navbarCollapse() {
    if ($("#mainNav").offset().top > 50) {
      $("#mainNav").addClass("navbar-shrink");
      $("#alertPrice").addClass("navbar-shrink");
    } else {
      $("#mainNav").removeClass("navbar-shrink");
      $("#alertPrice").removeClass("navbar-shrink");
    }
  }

  hideTheAlert = () => this.setState({ hideAlert: true });

  render() {
    return (
      <div>
        <Menu
          hideAlert={
            this.props.match.path.startsWith("/result") || this.state.hideAlert
          }
        />
        <header className="masthead text-center text-white">
          <div className="masthead-content intro-text">
            <div className="container">
              <MainFrame {...this.props} hideTheAlert={this.hideTheAlert} />
            </div>
          </div>
        </header>
      </div>
    );
  }
}

const MainFrame = props => {
  const { hideForm, hideTheAlert } = props;
  if (hideForm) {
    const { priceContext, translation } = props;

    return (
      <ResultHeader translation={translation} priceContext={priceContext} />
    );
  }

  return (
    <Row className="align-items-center">
      <Col lg={{ size: 4, offset: 1 }} className="mt-5 pt-5 mt-md-0 pt-md-0">
        <div className="header-text">
          <h4 className="header-text-first-line">
            {props.translation.t("home.title.mainTitle")}
          </h4>
          <h4 className="header-text-second-line">
            {props.translation.t("home.title.mainTitleSecondLine")}
          </h4>
        </div>

        <PriceForm hideTheAlert={hideTheAlert} />
      </Col>
      <Col
        md={6}
        className={`mx-auto order-first order-lg-2 mb-5 mb-lg-0 ${
          localStorage.getItem("OPPID") ? "pt-6" : ""
        } d-none d-lg-block`}
      >
        {window.isMobileDevice() ? null : (
          <div>
            <h2 className="desktop-header-text-first-line mb-0">
              {props.translation.t("home.title.mainTitle")}
            </h2>
            <h2 className="desktop-header-text-second-line">
              {props.translation.t("home.title.mainTitleSecondLine")}
            </h2>
          </div>
        )}
      </Col>
    </Row>
  );
};

MainFrame.propTypes = {
  hideForm: PropTypes.bool,
  priceContext: PropTypes.object.isRequired,
  translation: PropTypes.object.isRequired
};

export default UseTranslation(UsePriceContext(Main));
