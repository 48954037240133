/* eslint-disable react/no-string-refs */
import React from "react";
import Main from "components/Main/Main.jsx";
import Footer from "components/Footer/Footer.jsx";
import Rdv from "components/Result/Rdv.jsx";
import { UseTranslation } from "i18n/Translation";
import PropTypes from "prop-types";

class Result extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info"
    };
  }

  render() {
    const { translation } = this.props;

    return (
      <div className="wrapper">
        <div className="main-panel" ref="mainPanel">
          <div className="content">
            <Main {...this.props} routes={[]} hideForm />
            <Rdv />
          </div>
          <a id="goToRDV" className="btn btn-success" href="#RDV" hidden>
            {translation.t("result.btn.rdv")}
          </a>
          <Footer fluid />
        </div>
      </div>
    );
  }
}

Result.propTypes = {
  translation: PropTypes.object.isRequired
};

export default UseTranslation(Result);
