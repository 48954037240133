import React from 'react';
import PropTypes from "prop-types";
import { UncontrolledTooltip } from 'reactstrap';

const MyToolTip = props => {
  if (props.tooltiptext) {
    return (
      <div>
        <i
          id={`UncontrolledTooltip-id-${props.name}`}
          className="fas fa-info-circle"
        />
        <UncontrolledTooltip
          placement="right"
          target={`UncontrolledTooltip-id-${props.name}`}
        >
          {props.tooltiptext}
        </UncontrolledTooltip>
      </div>
    );
  }

  return null;
};

MyToolTip.propTypes = {
  tooltiptext: PropTypes.string,
  name: PropTypes.string
};

export default MyToolTip;