import React from "react";
import { UseTranslation } from "i18n/Translation";
import { Row, Col, Form, FormGroup, Input } from "reactstrap";
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";
import { sendEmail } from "AWS/aws.jsx";

class SectionContact extends React.Component {
  contactForm = [
    "vendorFirstName",
    "vendorName",
    "vendorEmail",
    "seller_phone"
  ];
  recaptchaRef = React.createRef();
  constructor(params) {
    super(params);

    this.state = {
      vendorFirstName: null,
      vendorName: null,
      vendorEmail: null,
      seller_phone: null,
      message: null,

      deactivateBtn: false
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  onSubmit(e) {
    e.preventDefault();

    this.recaptchaRef.current.execute();

    sendEmail(
      "A new contact form was submitted",
      this.props.translation.format(
        "A new form was submitted with the following information: <br /><b>Firstname</b>: {vendorFirstName}<br /><b>Name</b>: {vendorName}<br /><b>Email</b>: {vendorEmail}<br /><b>Phone nbr</b>: {seller_phone}<br /><b>Message</b>: {message}",
        this.state
      )
    );

    this.setState({ deactivateBtn: true });
  }

  render() {
    const { deactivateBtn } = this.state;
    const { t } = this.props.translation;

    const btnClasses = deactivateBtn
      ? "btn btn-success btn-lg rounded-pill text-center"
      : "btn btn-primary btn-lg rounded-pill text-center";

    return (
      <section className="section-contact" id="contact">
        <div className="container">
          <div className="text-center">
            <h1 className="sgtitle">{t("home.contactSection.title")}</h1>
          </div>
          <Form onSubmit={this.onSubmit}>
            <Row className="p-5">
              <Col lg={{ size: 5, offset: 1 }}>
                <div>
                  {this.contactForm.map((form, key) => (
                    <InputForm
                      key={key}
                      onChange={this.onChange}
                      name={form}
                      t={t}
                      required
                    />
                  ))}
                </div>
              </Col>
              <Col lg={6}>
                <InputForm
                  onChange={this.onChange}
                  name={"message"}
                  required
                  t={t}
                  type="textarea"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-center">
                  <ReCAPTCHA
                    ref={this.recaptchaRef}
                    size="invisible"
                    theme="dark"
                    sitekey="6LejAKsUAAAAAL1-kPUGphGafhh9VJxamxU8FnUi"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-center">
                  <input
                    type="submit"
                    className={btnClasses}
                    value={
                      deactivateBtn
                        ? t("home.contactSection.buttonSent")
                        : t("home.contactSection.buttonText")
                    }
                    disabled={deactivateBtn}
                  />
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </section>
    );
  }
}

const InputForm = props => {
  let { onChange, name, t, type } = props;

  if (!type) {
    type = "text";
  }

  return (
    <FormGroup>
      <h6 className="pd6">{t("home.form.{0}".replace("{0}", name))}</h6>
      <Input type={type} name={name} onChange={onChange} required />
    </FormGroup>
  );
};

SectionContact.propTypes = {
  translation: PropTypes.object.isRequired
};

InputForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string
};

export default UseTranslation(SectionContact);
