import React from "react";
import { Col } from "reactstrap";
import PropTypes from "prop-types";

import "./SectionPressBadge.css";

class SectionPressBadge extends React.Component {
  render() {
    return (
      <Col lg={12} md={12} sm={12} xs={12}>
        <div className="press-badge-element">
          <img
            className="img-fluid-press-badge"
            id="img-fluid-press-badge"
            src={this.props.img}
            alt=""
          />
        </div>
      </Col>
    );
  }
}

SectionPressBadge.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  img: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export default SectionPressBadge;
