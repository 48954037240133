import React from "react";
import { UseTranslation } from "i18n/Translation";
import { FormGroup, Input } from "reactstrap";
import PropTypes from "prop-types";
import {
  mobileAwareSelect,
  sellerPatienceLevelOptions
} from "variables/constants.jsx";

class FormThird extends React.Component {
  constructor(params) {
    super(params);

    this.state = {
      sellerPatienceLevelOptions
    };
  }

  render() {
    const { t } = this.props.translation;

    return (
      <div>
        <FormGroup>
          <h6 className="pd6">
            {t("home.form.sellerFinalAcceptablePrice")}
            {" *"}
          </h6>
          <Input
            onChange={this.props.onChange}
            name="sellerFinalAcceptablePrice"
            type="number"
          ></Input>
        </FormGroup>
        <FormGroup>
          <h6 className="pd6">
            {t("home.form.sellerPatienceLevel")}
            {" *"}
          </h6>
          {mobileAwareSelect(
            "sellerPatienceLevel",
            val => {
              this.props.onChange({
                target: { name: "sellerPatienceLevel", value: val.value }
              });
            },
            this.state.sellerPatienceLevelOptions.map(elem => {
              return { ...elem, label: t(elem.label) };
            }),
            false
          )}
        </FormGroup>
      </div>
    );
  }
}

FormThird.propTypes = {
  prospectionArgumentsForPurchasingKeys: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  translation: PropTypes.object.isRequired
};

export default UseTranslation(FormThird);
