import React from "react";
import { Col } from "reactstrap";
import PropTypes from "prop-types";

import "./SectionBadge.css";

class SectionBadge extends React.Component {
  render() {
    return (
      <Col lg={4} md={4} sm={4} xs={4}>
        <div className="badge-element">
          <img
            className="img-fluid-badge"
            id="img-fluid-badg"
            src={this.props.img}
            alt=""
          />
        </div>
        <h4 className="badge-title">{this.props.title}</h4>
      </Col>
    );
  }
}

SectionBadge.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  img: PropTypes.string.isRequired
};

export default SectionBadge;
