import React from "react";
import PropTypes from "prop-types";

const PriceContex = React.createContext({});

const PriceContexConsumer = PriceContex.Consumer;

const PriceContexProvider = PriceContex.Provider;

export function UsePriceContext(Component) {
  return function WrapperComponent(props) {
    return (
      <PriceContexConsumer>
        {state => <Component {...props} priceContext={state} />}
      </PriceContexConsumer>
    );
  };
}

export default class ProvidePriceContex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      price: 0,
      updatePrice: this.updatePrice,
      updateSellerInfo: this.updateSellerInfo,
      updateShowExtraFormButton: this.updateShowExtraFormButton,
      oppId: "",
      showExtraFormButton: false,
      sellerInfo: {
        vendorFirstName: "",
        vendorName: "",
        vendorEmail: "",
        seller_phone: "",
        carBrand: "",
        carModel: ""
      }
    };
  }

  updateShowExtraFormButton = showExtraFormButton => this.setState({ showExtraFormButton: showExtraFormButton === true ? true : false });

  updatePrice = price => this.setState({ price });

  updateSellerInfo = (oppId, sellerInfo) =>
    this.setState({ oppId, sellerInfo });

  render() {
    return (
      <div>
        <PriceContexProvider value={this.state}>
          {this.props.children}
        </PriceContexProvider>
      </div>
    );
  }
}

ProvidePriceContex.propTypes = {
  children: PropTypes.object
};
