/* eslint-disable react/no-string-refs */
import React from "react";
import { Row, Container, Col, Button } from "reactstrap";
import { UsePriceContext } from "contexts/PriceContext.jsx";
import { UseRdvContext } from "contexts/RdvContext.jsx";
import { UseTranslation } from "i18n/Translation";
import PropTypes from "prop-types";

class Rdv extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBookingCalendar: null
    };

    this.selectBookingCalendar = this.selectBookingCalendar.bind(this);
  }

  selectBookingCalendar(bCalend) {
    this.setState({
      selectedBookingCalendar: bCalend
    });
  }

  render() {
    const { priceContext, translation, rdvContext } = this.props;
    const { sellerInfo, oppId } = priceContext;

    const params = "&firstName={0}&lastName={1}&phone={2}&email={3}&field:6351469={4}"
      .replace("{0}", sellerInfo.vendorFirstName || "")
      .replace("{1}", sellerInfo.vendorName || "")
      .replace("{2}", sellerInfo.seller_phone || "")
      .replace("{3}", sellerInfo.vendorEmail || "")
      .replace("{4}", oppId);

    // Should never happen
    // In case something goes wrong -> do not display the rdv component
    if (rdvContext.bookingCalendars.length === 0) {
      return null;
    }

    if (this.state.selectedBookingCalendar) {
      return (
        <div id="RDV">
          {rdvContext.bookingCalendars.length > 1 && (
            <a
              id="goToRDV"
              className="btn btn-success"
              href="#RDV"
              onClick={() => this.setState({ selectedBookingCalendar: null })}
            >
              {translation.t("result.btn.back.to.select.city")}
            </a>
          )}

          <iframe
            id="iframe-acuity"
            title="acuityscheduling"
            src={this.state.selectedBookingCalendar.iframeLink + params}
            width="100%"
            height="1900"
            frameBorder="0"
          />
          <script
            src="https://embed.acuityscheduling.com/js/embed.js"
            type="text/javascript"
          />
        </div>
      );
    }

    if (rdvContext.bookingCalendars.length === 1) {
      this.selectBookingCalendar(rdvContext.bookingCalendars[0]);
      return null;
    }

    return (
      <div id="RDV">
        <Container>
          <div className="text-center mt-5">
            <h1 className="sgtitle">
              {translation.t("result.rdv.select.city")}
            </h1>
          </div>
          <Row className="mt-5">
            {rdvContext.bookingCalendars.map((bCalendar, key) => {
              return (
                <Col key={key} className="text-center">
                  <Button
                    className="btn btn-primary"
                    onClick={() => this.selectBookingCalendar(bCalendar)}
                  >
                    {translation.t(bCalendar.buttonText)}
                  </Button>
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    );
  }
}

Rdv.propTypes = {
  priceContext: PropTypes.object.isRequired,
  rdvContext: PropTypes.object.isRequired,
  translation: PropTypes.object.isRequired
};

export default UseTranslation(UsePriceContext(UseRdvContext(Rdv)));
