import React from 'react';
import { CardBody, Row, Col } from 'reactstrap';
import { TranslationConsumer } from "i18n/Translation";
import InputFile from './InputFile';
import { saveImage } from 'AWS/aws';

const formData = [
  {
    name: "photo_front_45deg",
    label: "extra.form.photo_front_45deg.label",
    explanation: "extra.form.photo_front_45deg.explanation",
    exampleImage: require("assets/img/example-front-45deg.jpg")
  },
  {
    name: "photo_center_console",
    label: "extra.form.photo_center_console.label",
    explanation: "extra.form.photo_center_console.explanation",
    exampleImage: require("assets/img/example-dashboard.jpg")
  },

  /*
  {
    name: "photo_dashboard",
    label: "extra.form.photo_dashboard.label",
    explanation: "extra.form.photo_dashboard.explanation",
    exampleImage: require("assets/img/example-dashboard.jpg")
  },
  {
    name: "photo_vin",
    label: "extra.form.photo_vin.label",
    explanation: "extra.form.photo_vin.explanation",
    exampleImage: require("assets/img/A1.jpg")
  },
  */
];

class SecondExtraForm extends React.Component {
  state = {
    photo_front_45deg: undefined,
    photo_center_console: undefined,
    /*
    photo_dashboard: undefined,
    photo_vin: undefined
    */
  }

  onChange = (key, data) => this.setState({ [key]: data });

  validate() {
    for (var property in this.state) {
      if (this.state[property]) {
        continue;
      }
      return false;
    }
    return true;
  }

  updateOpp(oppId) {
    return Promise.all([
      saveImage(oppId, this.state.photo_front_45deg),
      saveImage(oppId, this.state.photo_center_console),
      /*
      saveImage(oppId, this.state.photo_dashboard),
      saveImage(oppId, this.state.photo_vin),
      */
    ]);
  }

  render() {
    return (
        <TranslationConsumer>
          {translation =>
            <div>
              {formData.map(formdata =>
                <React.Fragment key={formdata.name}>
                  <h5>{translation.t(formdata.label)}</h5>
                  <Row className="align-items-center mb-5">
                    <Col xs={{ size: "12", order: 2}} md={{ size: "6", order: 1}}>
                      <CardBody className="text-left align-items-center d-flex">
                        <InputFile onChange={this.onChange} name={formdata.name} />
                      </CardBody>
                    </Col>
                    <Col xs={{ size: "12", order: 1}} md={{ size: "6", order: 2}}>
                      <CardBody className="text-left align-items-center d-flex">
                        <div>
                          <p>{translation.t(formdata.explanation)}</p>
                          <img alt="Explication" src={formdata.exampleImage} />
                        </div>
                      </CardBody>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </div>
          }
        </TranslationConsumer>
    );
  }
}

export default SecondExtraForm;
