import React from "react";
import { UseTranslation } from "i18n/Translation";
import PropTypes from "prop-types";

class FormForth extends React.Component {
  render() {
    const { translation } = this.props;
    const { t } = translation;

    let generalArgs = [];
    let carRelatedArgs = [];
    let reasonableSellerArgs = [];
    let unreasonableSellerArgs = [];
    this.props.prospectionArgumentsForPurchasingKeys.forEach(function(item) {
      if (item.includes("general") && item !== "{general_0}") {
        generalArgs.push(item);
      } else if (item.includes("reasonable") && item !== "{reasonable_0}") {
        reasonableSellerArgs.push(item);
      } else if (item.includes("unreasonable") && item !== "{unreasonable_0}") {
        unreasonableSellerArgs.push(item);
      } else if (item !== "{general_0}" && item !== "{specific_0}") {
        carRelatedArgs.push(item);
      }
    });

    let generalArgsHtmlTitle = "";
    let generalArgsHtmlPoints = "";
    if (generalArgs.length !== 0) {
      generalArgsHtmlTitle = <h5 key="general_0">{t("{general_0}")}</h5>;
      generalArgsHtmlPoints = generalArgs.map((arg, key) => (
        <h6 key={key}>{t(arg)}</h6>
      ));
    }

    let carRelatedArgsHtmlTitle = "";
    let carRelatedArgsHtmlPoints = "";
    if (carRelatedArgs.length !== 0) {
      carRelatedArgsHtmlTitle = <h5 key="specific_0">{t("{specific_0}")}</h5>;
      carRelatedArgsHtmlPoints = carRelatedArgs.map((arg, key) => (
        <h6 key={key}>{t(arg)}</h6>
      ));
    }

    let reasonableSellerArgsHtmlTitle = "";
    let reasonableSellerArgsHtmlPoints = "";
    if (reasonableSellerArgs.length !== 0) {
      reasonableSellerArgsHtmlTitle = (
        <h5 key="reasonable_0">{t("{reasonable_0}")}</h5>
      );
      reasonableSellerArgsHtmlPoints = reasonableSellerArgs.map((arg, key) => (
        <h6 key={key}>{t(arg)}</h6>
      ));
    }

    let unreasonableSellerArgsHtmlTitle = "";
    let unreasonableSellerArgsHtmlPoints = "";
    if (unreasonableSellerArgs.length !== 0) {
      unreasonableSellerArgsHtmlTitle = (
        <h5 key="unreasonable_0">{t("{unreasonable_0}")}</h5>
      );
      unreasonableSellerArgsHtmlPoints = unreasonableSellerArgs.map(
        (arg, key) => <h6 key={key}>{t(arg)}</h6>
      );
    }

    return (
      <div>
        {generalArgsHtmlTitle}
        {generalArgsHtmlPoints}

        {carRelatedArgsHtmlTitle}
        {carRelatedArgsHtmlPoints}

        {reasonableSellerArgsHtmlTitle}
        {reasonableSellerArgsHtmlPoints}

        {unreasonableSellerArgsHtmlTitle}
        {unreasonableSellerArgsHtmlPoints}
      </div>
    );
  }
}

FormForth.propTypes = {
  prospectionArgumentsForPurchasingKeys: PropTypes.array.isRequired,
  opp: PropTypes.object.isRequired,
  translation: PropTypes.object.isRequired
};

export default UseTranslation(FormForth);
