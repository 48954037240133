import React from 'react';
import Rdv from "components/Result/Rdv.jsx";
import { UseTranslation } from "i18n/Translation";
import { Row, Col } from 'reactstrap';

class FinalExtraForm extends React.Component {
  render() {
    const { translation } = this.props;

    return (
      <div>
        <Row>
          <Col>
            <h5 className="text-center mb-5">{translation.t("result.extra.form.title")}</h5>
            <p className="mx-5">{translation.t("result.extra.form.what.next")}</p>
          </Col>
        </Row>
        <Rdv />
      </div>
    );
  }
}

export default UseTranslation(FinalExtraForm);