import React from "react";
import ReactDOM from "react-dom";

import App from "App";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss";
import "assets/demo/agency.css";
import "assets/demo/demo.css";

import "AWS/config.jsx";

import ProvideTranslation from "i18n/Translation";
import ProvidePriceContex from "contexts/PriceContext.jsx";
import ProvideLoginContex from "contexts/LoginContext.jsx";
import ProvideRdvContex from "contexts/RdvContext.jsx";

import detectBrowserLanguage from "detect-browser-language";

const AllApp = () => {
  return (
    <ProvideTranslation
      defaultLg="en"
      currentLg={detectBrowserLanguage()
        .substring(0, 2)
        .toLowerCase()}
    >
      <ProvidePriceContex>
        <ProvideLoginContex>
          <ProvideRdvContex>
            <App />
          </ProvideRdvContex>
        </ProvideLoginContex>
      </ProvidePriceContex>
    </ProvideTranslation>
  );
};

ReactDOM.render(AllApp(), document.getElementById("root"));
